import React from "react"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Hero from "../components/hero"
import ServiceCard from "../components/serviceCard"
import OwnerCard from "../components/ownerCard"
import BeforeFooter from "../components/beforeFooter"
import ValueProp from "../components/valueProp"

const SkillsContainer = styled.div`
  margin: 64px 20px 0;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1050px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`

const OwnerContainer = styled.div`
  margin: 0 20px;
  @media screen and (min-width: 1050px) {
    display: flex;
    justify-content: space-between;
    margin: 0;
    z-index: 1;
  }
`

const HideOnDesktop = styled.div`
  @media screen and (min-width: 1050px) {
    display: none;
  }
`
const HideOnMobile = styled.div`
  display: none;
  @media screen and (min-width: 1050px) {
    display: block;
  }
`
const LeftDecoWrapper = styled.div`
  position: absolute;
  height: 683px;
  width: 320px;
  left: 0;
  margin-top: -150px;
  background: url("./HomeLeft.svg");
  filter: blur(4px);
`

const RightDecoWrapper = styled.div`
  position: absolute;
  height: 683px;
  width: 406px;
  right: 0;
  margin-top: -100px;
  background: url("./HomeRight.svg");
  z-index: -10;
  filter: blur(4px);
`

const Spacer = styled.div`
  height: 24px;
`

const IndexPage = ({ location }) => (
  <>
    <Seo title="Commercialista Brescia" />
    <Hero />
    <SkillsContainer>
      <ServiceCard color="blue" title="Fiscale">
        <p>Assistenza nelle verifiche fiscali e nel contenzioso tributario.</p>
        <p>Assistenza fiscale continuativa per società e persone fisiche.</p>
        <p>Consulenza amministrativa e contabile.</p>
        <p>Assistenza fiscale nell’ambito di operazioni straordinarie.</p>
      </ServiceCard>
      <Spacer />
      <ServiceCard color="grey" title="Aziendale">
        <p>Valutazioni di aziende, rami d’azienda.</p>
        <p>Assistenza per la predisposizione di business plan.</p>
        <p>Assistenza nella risoluzione di crisi d’impresa.</p>
        <p>Due diligence societarie, legali e fiscali.</p>
      </ServiceCard>
      <Spacer />
      <ServiceCard color="red" title="Legale & Societaria">
        <p>Consulenza societaria e commerciale.</p>
        <p>
          Consulenza nelle ristrutturazioni e nelle riorganizzazioni societarie.
        </p>
        <p>Consulenza nei passaggi generazionali.</p>
      </ServiceCard>
    </SkillsContainer>
    <ValueProp>
      <h2 style={{ fontSize: "32px", fontWeight: "bold" }}>Lo studio</h2>
      <span style={{ fontSize: "16px" }}>
        Lo studio commercialisti associati Benetti Filippini si trova a Brescia,
        in Via Malta, 8, Fondato nel 1995 da Mariacecilia Benetti e Ombretta
        Filippini.
      </span>
    </ValueProp>
    <HideOnMobile>
      <LeftDecoWrapper />
      <RightDecoWrapper />
    </HideOnMobile>
    <OwnerContainer>
      <OwnerCard name="ceciliabenetti" />
      <HideOnDesktop>
        <div style={{ height: "96px" }}></div>
      </HideOnDesktop>
      <OwnerCard name="ombrettafilippini" />
      <HideOnDesktop>
        <div style={{ height: "128px" }}></div>
      </HideOnDesktop>
    </OwnerContainer>

    <BeforeFooter />
  </>
)

export default IndexPage
