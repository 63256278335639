import React from "react"
import styled from "@emotion/styled"

const Text = styled.div`
  margin: 0 20px 0;
  font-size: 32px;
  color: #3e4c59;
  line-height: 130%;
  font-weight: bold;
  @media screen and (min-width: 1050px) {
    width: 100%;
    font-weight: bold;
    font-size: 32px;
  }
`

const Container = styled.div`
  margin: 96px 0;
  @media screen and (min-width: 1050px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 192px 0;
  }
`

const BeforeFooter = () => (
  <Container>
    <Text>
      “Affiancare il cliente nella comprensione delle regole di economia
      aziendale e finanziaria e delle disposizioni legislative, mettendo al
      servizio le nostre conoscenze professionali e gli strumenti tecnici in
      modo semplice e diretto.”
    </Text>
  </Container>
)

export default BeforeFooter
