import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import ValueProp from "./valueProp"
import Button from "./button"

const Headline = styled.h1`
  margin: 16px 20px 0;
  color: #3e4c59;
  font-size: 32px;
  font-weight: 900;
  line-height: 120%;
  @media screen and (min-width: 1050px) {
    font-size: 48px;
    font-weight: bolder;
    margin: 16px 0;
    & br {
      display: none;
    }
  }
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    font-size: 48px;
  }
`

const PunchLine = styled.p`
  margin: 16px 20px 0;
  color: #3e4c59;
  font-size: 22px;
  font-weight: 900;
  line-height: 120%;
  @media screen and (min-width: 1050px) {
    font-size: 24px;
    font-weight: 500;
    margin: 16px 0;
    & br {
      display: none;
    }
  }
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    font-size: 22px;
    margin: 16px 20px 0;
    font-weight: 500;
  }
`

const StudioLogo = styled(motion.h1)`
  margin: 0 20px;
  font-size: 14px;
  color: #3e4c59;
  font-weight: ${props => (props.thin ? "200" : "bold")};
  @media screen and (min-width: 1050px) {
    font-size: 16px;
    margin: 0;
  }
`

const LogoContainer = styled(motion.div)`
  margin-top: 24px;
  @media screen and (min-width: 1050px) {
    margin-top: 96px;
  }
`

const CTA = styled.div`
  margin: 32px 20px 0;
  display: flex;
  @media screen and (min-width: 1050px) {
    margin: 48px 0 0;
    width: 30%;
  }
`

const Hero = () => (
  <>
    <LogoContainer>
      <StudioLogo thin>Studio commercialisti associati</StudioLogo>
      <StudioLogo style={{ marginTop: "-4px" }}>
        &mdash; Benetti Filippini
      </StudioLogo>
    </LogoContainer>
    <div>
      <Headline>
        Aiutiamo l’imprenditore nella gestione della sua impresa
      </Headline>
      <PunchLine>
        Attraverso professionalità, tecniche e strumenti fondamentali.
      </PunchLine>
    </div>
    <CTA>
      <Button hasRightMargin primary>
        <Link to="/contattaci">Contattaci</Link>
      </Button>
      <Button>
        <Link to="/servizi" style={{ color: "#7B8794" }}>
          Scopri i servizi
        </Link>
      </Button>
    </CTA>
    <ValueProp key="valueprop">
      <h2 style={{ fontSize: "32px", fontWeight: "bold" }}>
        Assistenza e consulenza
      </h2>
      <span style={{ fontSize: "16px" }}>
        In tutte le aree che interessano la vita di un’ impresa o di un
        lavoratore autonomo.
      </span>
    </ValueProp>
  </>
)

export default Hero
