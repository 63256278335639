import styled from "@emotion/styled"

const ValueProp = styled.div`
  margin: 96px 20px 48px;
  font-size: 20px;
  color: #3e4c59;
  line-height: 140%;
  @media screen and (min-width: 1050px) {
    margin: 232px 0 64px;
  }
  @media screen and (min-width: 1400px) {
    margin: 292px 0 64px;
  }
`
export default ValueProp
